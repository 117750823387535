var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.pageListShow,
          expression: "pageListShow",
        },
      ],
      staticClass: "page-list",
    },
    [
      _c("div", { staticClass: "pagetion" }, [
        _vm.allOption.find((option) => option.title == _vm.optionTitle)
          ? _c(
              "div",
              { staticClass: "pagetion-content" },
              _vm._l(
                _vm.allOption.find((option) => option.title == _vm.optionTitle)
                  .manuscriptList,
                function (item, idx) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "pagetion-content-show" },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.selectIdx == idx
                              ? "pagetion-item-select pagetion-item"
                              : "pagetion-item",
                          on: {
                            click: function ($event) {
                              return _vm.changeSelectIdx(idx)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(idx + 1) + "\n        "
                          ),
                        ]
                      ),
                      idx <
                      _vm.allOption.find(
                        (option) => option.title == _vm.optionTitle
                      ).manuscriptList.length -
                        1
                        ? _c("div", { staticClass: "pagetion-item-label" }, [
                            _vm._v("\n          ●\n        "),
                          ])
                        : _vm._e(),
                    ]
                  )
                }
              ),
              0
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "list-slider" }, [
        _vm.taskDetail.productionName
          ? _c("div", { staticClass: "list-title" }, [
              _c(
                "div",
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "option-filter-prop": "label",
                        "show-search": "",
                        placeholder: "请选择章节",
                      },
                      on: { change: _vm.chapterChange },
                      model: {
                        value: _vm.chapterId,
                        callback: function ($$v) {
                          _vm.chapterId = $$v
                        },
                        expression: "chapterId",
                      },
                    },
                    _vm._l(_vm.chapterList, function (chapter) {
                      return _c(
                        "a-select-option",
                        {
                          key: chapter.chapterId,
                          attrs: {
                            label: chapter.label,
                            value: chapter.chapterId,
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(chapter.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-top": "5px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "option-filter-prop": "label",
                        "show-search": "",
                        placeholder: "请选择稿件",
                      },
                      on: { change: _vm.pageOptionChange },
                      model: {
                        value: _vm.optionTitle,
                        callback: function ($$v) {
                          _vm.optionTitle = $$v
                        },
                        expression: "optionTitle",
                      },
                    },
                    _vm._l(_vm.allOption, function (option) {
                      return _c(
                        "a-select-option",
                        {
                          key: option.title,
                          attrs: { label: option.title, value: option.title },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(option.title) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          [
            _c("a-slider", {
              attrs: { "default-value": 100, max: 100, min: 10 },
              on: { change: _vm.handledChange },
            }),
          ],
          1
        ),
      ]),
      _vm.allOption.find((option) => option.title == _vm.optionTitle)
        ? _c(
            "div",
            { staticClass: "list-content" },
            _vm._l(
              _vm.allOption.find((option) => option.title == _vm.optionTitle)
                .manuscriptList,
              function (item, idx) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    class:
                      _vm.selectIdx == idx
                        ? "page-item-select page-item"
                        : "page-item",
                  },
                  [
                    _c("img", {
                      ref: item.id + "pageImg",
                      refInFor: true,
                      style: "width:" + _vm.sliderValue + "%;height:auto",
                      attrs: { src: item.filePath },
                      on: { click: (e) => _vm.changeShowScroll(e, idx) },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "word-wrap": "break-word",
                          "white-space": "pre-line",
                        },
                      },
                      [_vm._v(_vm._s(item.fileName))]
                    ),
                  ]
                )
              }
            ),
            0
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.confirmVisible,
            wrapClassName: "confirm-modal",
            bodyStyle: { padding: "32px", paddingBottom: "24px" },
            width: "416px",
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.confirmVisible = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                display: "flex",
                "align-items": "center",
                "font-weight": "500",
                color: "rgba(0, 0, 0, 0.85)",
              },
            },
            [
              _c("a-icon", {
                staticStyle: {
                  "font-size": "22px",
                  "margin-right": "15px",
                  color: "orange",
                },
                attrs: { type: "question-circle" },
              }),
              _vm._v("是否加载已锁定状态的文件?\n    "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                height: "62px",
                display: "flex",
                "align-items": "end",
                "justify-content": "end",
              },
            },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleConfimCancel(_vm.confirmTask)
                    },
                  },
                },
                [_vm._v("\n        不加载\n      ")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "8px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfimOk(_vm.confirmTask)
                    },
                  },
                },
                [_vm._v("\n        加载\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }